
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
//@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

$slider-header-height: 11rem;
$slider-content-center: false;
$slider-color: #fff;
$slider-max-width: 75rem;
$slider-full-window: true;
$slider-height: 700px;
$slider-arrow: true;
$slider-dot: true;

@mixin slick-arrows-size($arrow-width, $arrow-height){
  .slick-arrow{
    width: $arrow-width;
    height: $arrow-height;
  }
}

@mixin slick-arrows-position($arrow-left, $arrow-right){
  .slick-arrow{
    &.slick-prev{
      left: $arrow-left;
    }
    &.slick-next{
      right:  $arrow-right;
    }
  }
}

@mixin slick-arrows($arrow-width: $slider-arrow-width, $arrow-height: $slider-arrow-height,$arrow-background: $slider-arrow-background,$arrow-radius: $slider-arrow-radius,$arrow-border: $slider-arrow-border,$arrow-left: $slider-arrow-left,$arrow-right: $slider-arrow-right,$arrow-before-size: $slider-arrow-before-size,$arrow-prev-code: $slider-arrow-prev-code,$arrow-next-code: $slider-arrow-next-code){
  .slick-arrow{
    position:absolute;
    top:50%;
    margin-top: -8rem;
    width: $arrow-width;
    height: $arrow-height;
    @include border-radius($arrow-radius);
    //@include transform(translateY(-50%));
    border: $arrow-border;
    font-size:0;
    background: $arrow-background;
    outline: 0;
    z-index: 22;
    overflow: hidden;
    cursor: pointer;
    &:before{
      font-size: $arrow-before-size;
      color: #fff;
    }
    &.slick-prev{
      left: $arrow-left;
      //@include transform(translateX(-50%));
      &:before{
        display: block;
        @include transform(translateX(-15%));
        content: $arrow-prev-code;
      }
    }
    &.slick-next{
      right: $arrow-right;
      @include border-radius(999px 0 0 999px);
      //@include transform(translateX(50%));
      &:before{
        display: block;
        @include transform(translateX(15%));
        content: $arrow-next-code;
      }
    }
  }
}


@mixin slick-dots($dot-bottom: $slider-dot-bottom, $dot-padding: $slider-dot-padding, $dot-width: $slider-dot-width, $dot-height: $slider-dot-height, $dot-border: $slider-dot-border, $dot-radius: $slider-dot-radius, $dot-background: $slider-dot-background, $dot-active: $slider-dot-active){
  .slick-dots{
    @include flex-block;
    @include align-items(center);
    @include justify-content(center);
    position: relative;
    top:-6rem;
    left:0;
    width:100%;
    max-width: 169rem;
    margin:auto;
    bottom: $dot-bottom;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    @include media(md){
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }
    li{
      margin:0;
      padding: $dot-padding;
      &:before{
        display:none;
      }
      &:last-child{
        padding:0;
      }
      &:first-child:last-child{
        display: none;
      }
      button{
        font-size: 0;
        outline: 0;
        @include pux-static-size("width", $dot-width);
        @include pux-static-size("height", $dot-height);
        border: none;
        background: $dot-background;
        @include border-radius($dot-radius);
        padding: 0;
        @include flex-block;
        @include align-items(center);
        @include justify-content(center);
        &:before{
          content:"";
          @include pux-static-size("width", 9px);
          @include pux-static-size("height", 9px);
          background: #fff;
          @include border-radius(50%);
        }
      }
      &.slick-active{
        button{
          width: 23px;
          height: 23px;
          border: 4px solid #ffffff;
          &:before{
            background: transparent;
          }
        }
      }
    }
  }
}

.slider-init{
  .main-slider{
    width: 100%;
    background-repeat: no-repeat !important;
    @include background-size(cover);
    @include flex-block;
    @include flex-direction(row);
    &-inner{
      width:100%;
      padding:6rem 0 6rem 0;
      @if $slider-full-window{
        @include calc("min-height", "100vh - "+ $slider-header-height);
      } @else {
         min-height: #{$slider-height}rem;
      }
      @include flex-block;
      @include flex-direction(column);
      @include align-items(center);
      @include justify-content(center);
      .pux-container{
        text-align: center;
        @include media(xl){
          text-align: left;
          @include flex-block;
          @include align-items(center);
        }
      }
      &-content{
        width: 100%;
        max-width: $slider-max-width;
        margin: auto;
        @include media(xl){
          margin-left: 0;
          width: 50%;
        }
        @if $slider-content-center{
          text-align: center;
        }
        &-text{
          color: $slider-color;
          @include pux-scale-with-min("line-height", 32px, 22px);
          h1,
          h2{
            color: $slider-color;
            padding-bottom: 2.5rem;
          }
        }
        &-btn{
          padding: 5rem 0 0 0;
        }
      }
      &-image{
        margin-top: 5rem;
        @include media(xl){
          width: 50%;
        }
        img{
          object-fit: cover;
        }
      }
      .pux-container{
        width:100%;
      }
    }
  }
  @if $slider-arrow{
    @include slick-arrows;
  }
  @if $slider-dot{
    @include slick-dots;
  }
}
