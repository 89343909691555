
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


label{
  display: block;
  margin:1rem 0 0.5rem 0;
  @include pux-static-size("font-size", 14px);
}

.form-control,
textarea,
input[type="text"],
input[type="password"]{
  width:100%;
  @include pux-scale-with-min("height", $input-height, 40px);
  padding: 0 1.5rem;
  border: 1px solid $border-color;
  background: $input-background;
  color:$base-black;
  @include pux-static-size("font-size", 14px);
  @include placeholder{
    color: $color-placeholder;
  }
  &:focus{
    outline: 0;
    border: 1px solid $focus-border-color;
  }
}

textarea{
  @include pux-scale-with-min("height", $textarea-height, 100px);
  padding: 1.5rem
    &:focus{
      border: 1px solid $focus-border-color;
    }
}


input[type="submit"]{
  cursor: pointer;
  &:focus,
  &:hover{

  }
}

.required{
    label{
        &:before{
            content:"*";
            display: inline-block;
            vertical-align: 0.6rem;
            margin: 0 2px 0 0;
            @include pux-static-size("font-size", 10px);
        }
    }
}

label{
  &.required{
    &:after{
        content:"*";
        display: inline-block;
        vertical-align: 0.6rem;
        margin: 0 2px 0 0;
        color:$main-color;
        @include pux-static-size("font-size", 10px);
    }
  }
}

.file{
  .uploader-upload{
      display:none;
  }
  label{
      display:inline-block;
      padding:1rem 2rem;
      text-transform:uppercase;
      color: $upload-color;
      background: $upload-background;
      cursor:pointer;
      @include pux-static-size("font-size", 14px);
      @include border-radius(0);
      max-width: 24rem;
      margin:0;
      width: 100%;
      text-align:center;
      cursor:pointer;
      letter-spacing:1px;
      outline:0 !important;
  }
  .file-name{
      padding:0.5rem;
      background: $file-name-background;
      color: $file-name-color;
  }
}

@if($use-blink-error == 1){
    .Error{
        select,
        .selectric,
        input[type="text"],
        textarea{
            border: 1px solid $error-input-border !important;
            @include box-shadow(inset 0 0 0 1px $error-input-border);
            @include animation(normal, blink, 1s, ease, 2);
        }
    }

    @include keyframes(blink){
        0% {
            background: $error-blink-start;
        }
        50% {
            background: $error-blink-final;
        }
        100% {
            background: $error-blink-start;
        }
    }
}

.EditingFormErrorLabel{
    @include pux-static-size("font-size", 14px);
    color:$error-label-color;
    display:block;
    margin:0.5rem 0 0 0;
}

.ErrorLabel{
    color:$error-label-color;
    display:block;
    margin:0.5rem 0;
}

.radio{
    input[type="radio"]{
        display:none;
        & +label{
            position:relative;
            padding:0 0 10px 25px;
            display: inline-block;
            margin:0;
            &:before{
                content:"";
                @include border-radius(50%);
                width:16px;
                height:16px;
                background:$radio-background;
                display:block;
                position:absolute;
                left:0;
                top:3px;
                border:1px solid $radio-border;
                @include transition;
            }
            &:after{
                content:"";
                display:block;
                left:5px;
                top:8px;
                width:6px;
                height:6px;
                background:$radio-after-background;
                @include border-radius(50%);
                position:absolute;
                @include opacity(0);
                @include transition;
            }
        }
        &:checked{
            & +label{
                &:before{
                    border:1px solid $radio-active-border;
                }
                &:after{
                    @include opacity(1);
                }
            }
        }
    }
}

.checkbox{
    input[type="checkbox"]{
        display:none;
        & +label{
            position:relative;
            padding:0 0 10px 25px;
            margin:0;
            display: inline-block;
            &:before{
                content:"";
                width:16px;
                height:16px;
                background:$checkbox-background;
                display:block;
                position:absolute;
                left:0;
                top:3px;
                border:1px solid $checkbox-border;
                @include transition;
            }
            &:after{
                content:"";
                display:block;
                left:5px;
                top:8px;
                width:6px;
                height:6px;
                background:$checkbox-after-background;
                position:absolute;
                @include opacity(0);
                @include transition;
            }
        }
        &:checked{
            & +label{
                &:before{
                    border:1px solid $checkbox-active-border;
                }
                &:after{
                    @include opacity(1);
                }
            }
        }
    }
}

.row-form{
  @include flex-block;
  @include flex-wrap(wrap);
  margin:0 -1rem;
}

.form-item-100,
.form-item-50,
.form-item-33,
.form-item-25{
  padding:0 1rem 1rem 1rem;
}

.form-item-100{
  width:100%;
}

.form-item-50{
  width:100%;
  @include media(md){
    width:50%;
  }
}

.form-item-33{
  width:100%;
  @include media(md){
    width:33.33%;
  }
}

.form-item-25{
  width:100%;
  @include media(md){
    width:25%;
  }
}
