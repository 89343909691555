@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700&subset=latin-ext");
@import url(../fonts/style.css);
.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 172rem; }
  .pux-container:after {
    display: block;
    content: "";
    clear: both; }
  @media (min-width: 768px) {
    .pux-container {
      padding-left: 6rem;
      padding-right: 6rem; } }
  @media (min-width: 1200px) {
    .pux-container {
      padding-left: 26rem;
      padding-right: 26rem; } }

.pux-container .row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  @media (min-width: 768px) {
    .pux-container .row {
      margin-left: -6rem;
      margin-right: -6rem; } }

.pux-container .col-1, .pux-container .col-2, .pux-container .col-3, .pux-container .col-4, .pux-container .col-5, .pux-container .col-6, .pux-container .col-7, .pux-container .col-8, .pux-container .col-9, .pux-container .col-10, .pux-container .col-11, .pux-container .col-12, .pux-container .col,
.pux-container .col-xs-1, .pux-container .col-xs-2, .pux-container .col-xs-3, .pux-container .col-xs-4, .pux-container .col-xs-5, .pux-container .col-xs-6, .pux-container .col-xs-7, .pux-container .col-xs-8, .pux-container .col-xs-9, .pux-container .col-xs-10, .pux-container .col-xs-11, .pux-container .col-xs-12,
.pux-container .col-auto, .pux-container .col-sm-1, .pux-container .col-sm-2, .pux-container .col-sm-3, .pux-container .col-sm-4, .pux-container .col-sm-5, .pux-container .col-sm-6, .pux-container .col-sm-7, .pux-container .col-sm-8, .pux-container .col-sm-9, .pux-container .col-sm-10, .pux-container .col-sm-11, .pux-container .col-sm-12, .pux-container .col-sm,
.pux-container .col-sm-auto, .pux-container .col-md-1, .pux-container .col-md-2, .pux-container .col-md-3, .pux-container .col-md-4, .pux-container .col-md-5, .pux-container .col-md-6, .pux-container .col-md-7, .pux-container .col-md-8, .pux-container .col-md-9, .pux-container .col-md-10, .pux-container .col-md-11, .pux-container .col-md-12, .pux-container .col-md,
.pux-container .col-md-auto, .pux-container .col-lg-1, .pux-container .col-lg-2, .pux-container .col-lg-3, .pux-container .col-lg-4, .pux-container .col-lg-5, .pux-container .col-lg-6, .pux-container .col-lg-7, .pux-container .col-lg-8, .pux-container .col-lg-9, .pux-container .col-lg-10, .pux-container .col-lg-11, .pux-container .col-lg-12, .pux-container .col-lg,
.pux-container .col-lg-auto, .pux-container .col-xl-1, .pux-container .col-xl-2, .pux-container .col-xl-3, .pux-container .col-xl-4, .pux-container .col-xl-5, .pux-container .col-xl-6, .pux-container .col-xl-7, .pux-container .col-xl-8, .pux-container .col-xl-9, .pux-container .col-xl-10, .pux-container .col-xl-11, .pux-container .col-xl-12, .pux-container .col-xl,
.pux-container .col-xl-auto {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .pux-container .col-1, .pux-container .col-2, .pux-container .col-3, .pux-container .col-4, .pux-container .col-5, .pux-container .col-6, .pux-container .col-7, .pux-container .col-8, .pux-container .col-9, .pux-container .col-10, .pux-container .col-11, .pux-container .col-12, .pux-container .col,
    .pux-container .col-xs-1, .pux-container .col-xs-2, .pux-container .col-xs-3, .pux-container .col-xs-4, .pux-container .col-xs-5, .pux-container .col-xs-6, .pux-container .col-xs-7, .pux-container .col-xs-8, .pux-container .col-xs-9, .pux-container .col-xs-10, .pux-container .col-xs-11, .pux-container .col-xs-12,
    .pux-container .col-auto, .pux-container .col-sm-1, .pux-container .col-sm-2, .pux-container .col-sm-3, .pux-container .col-sm-4, .pux-container .col-sm-5, .pux-container .col-sm-6, .pux-container .col-sm-7, .pux-container .col-sm-8, .pux-container .col-sm-9, .pux-container .col-sm-10, .pux-container .col-sm-11, .pux-container .col-sm-12, .pux-container .col-sm,
    .pux-container .col-sm-auto, .pux-container .col-md-1, .pux-container .col-md-2, .pux-container .col-md-3, .pux-container .col-md-4, .pux-container .col-md-5, .pux-container .col-md-6, .pux-container .col-md-7, .pux-container .col-md-8, .pux-container .col-md-9, .pux-container .col-md-10, .pux-container .col-md-11, .pux-container .col-md-12, .pux-container .col-md,
    .pux-container .col-md-auto, .pux-container .col-lg-1, .pux-container .col-lg-2, .pux-container .col-lg-3, .pux-container .col-lg-4, .pux-container .col-lg-5, .pux-container .col-lg-6, .pux-container .col-lg-7, .pux-container .col-lg-8, .pux-container .col-lg-9, .pux-container .col-lg-10, .pux-container .col-lg-11, .pux-container .col-lg-12, .pux-container .col-lg,
    .pux-container .col-lg-auto, .pux-container .col-xl-1, .pux-container .col-xl-2, .pux-container .col-xl-3, .pux-container .col-xl-4, .pux-container .col-xl-5, .pux-container .col-xl-6, .pux-container .col-xl-7, .pux-container .col-xl-8, .pux-container .col-xl-9, .pux-container .col-xl-10, .pux-container .col-xl-11, .pux-container .col-xl-12, .pux-container .col-xl,
    .pux-container .col-xl-auto {
      padding-left: 6rem;
      padding-right: 6rem; } }

.pux-fluid-container {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .pux-fluid-container {
      padding-left: 6rem;
      padding-right: 6rem; } }
  @media (min-width: 1200px) {
    .pux-fluid-container {
      padding-left: 12rem;
      padding-right: 12rem; } }

#homepage header {
  background-color: #f3f3f3; }

header {
  width: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 77; }
  @media (min-width: 992px) {
    header {
      box-shadow: 0 0 38px rgba(0, 0, 0, 0.23); } }
  @media (min-width: 992px) {
    header.scrolled .header {
      padding: 2rem 0rem; } }
  @media (max-width: 991px) {
    header .pux-container {
      padding: 0; } }
  header .header {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    box-shadow: 0 0 38px rgba(0, 0, 0, 0.23);
    padding: 4rem 15px;
    position: relative;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    @media (min-width: 768px) {
      header .header {
        padding: 4rem 6rem; } }
    @media (min-width: 992px) {
      header .header {
        padding: 4rem 0;
        box-shadow: none;
        z-index: 200; } }
    header .header-logo a {
      display: inline-block; }
      header .header-logo a span {
        display: none; }
      header .header-logo a img {
        height: 27px !important;
        width: 108px; }
        @media (min-width: 768px) {
          header .header-logo a img {
            height: 54px !important;
            width: 216px; } }
    header .header-menu {
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      -webkit-transform: translateX(1000px);
      -moz-transform: translateX(1000px);
      -ms-transform: translateX(1000px);
      -o-transform: translateX(1000px);
      transform: translateX(1000px);
      position: absolute;
      top: 100%;
      z-index: -1;
      right: 0;
      background: #f3f3f3;
      width: 240px;
      height: 100vh; }
      @media (min-width: 545px) {
        header .header-menu {
          width: 320px; } }
      @media (min-width: 992px) {
        header .header-menu {
          -webkit-transform: translateX(0px);
          -moz-transform: translateX(0px);
          -ms-transform: translateX(0px);
          -o-transform: translateX(0px);
          transform: translateX(0px);
          display: block;
          position: static;
          background: none;
          width: auto;
          height: auto; } }
      header .header-menu ul {
        margin: 0;
        padding: 0;
        list-style: none;
        position: relative;
        z-index: -1;
        text-align: center; }
        @media (min-width: 992px) {
          header .header-menu ul {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-align: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center; } }
        header .header-menu ul li {
          display: block;
          position: relative;
          padding: 0 5rem; }
          @media (min-width: 992px) {
            header .header-menu ul li {
              position: static;
              margin: 0 1rem;
              padding: 0; } }
          header .header-menu ul li:last-child a {
            padding-right: 0; }
          header .header-menu ul li a {
            display: block;
            padding: 0 3.5rem;
            color: #1a1a1a;
            font-weight: 700; }
            @media (max-width: 991px) {
              header .header-menu ul li a {
                border-bottom: 1px solid #b9b9b9;
                padding: 3rem 0; } }
            header .header-menu ul li a:focus, header .header-menu ul li a:hover {
              color: #9280ac;
              text-decoration: none; }
          header .header-menu ul li.Highlighted a {
            color: #9280ac; }
          header .header-menu ul li ul {
            background: #f3f3f3;
            display: none;
            width: 100%;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            visibility: visible;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
            @media (min-width: 992px) {
              header .header-menu ul li ul {
                position: absolute;
                width: 20rem;
                visibility: hidden;
                opacity: 0;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                filter: alpha(opacity=0);
                display: block; } }
          header .header-menu ul li:hover > ul {
            visibility: visible;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100); }
          header .header-menu ul li.open .menu-openner:after {
            -ms-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
          header .header-menu ul li.open ul {
            display: block; }
      header .header-menu .menu-openner {
        position: absolute;
        top: 0;
        right: 0;
        background: #9280ac;
        color: #fff;
        width: 6rem;
        height: 6rem; }
        @media (min-width: 992px) {
          header .header-menu .menu-openner {
            display: none; } }
        header .header-menu .menu-openner:after, header .header-menu .menu-openner:before {
          content: "";
          width: 70%;
          height: 2px;
          background: #fff;
          position: absolute;
          left: 15%;
          top: 50%;
          margin-top: -1px; }
        header .header-menu .menu-openner:after {
          -ms-transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
    @media (min-width: 992px) {
      header .header-hamburger {
        display: none; } }
    header .header-hamburger .open-menu {
      width: 6rem;
      height: 6rem;
      position: relative;
      display: block; }
      header .header-hamburger .open-menu span {
        width: 5rem;
        position: absolute;
        left: 0.5rem;
        height: 4px;
        background: #1a1a1a;
        margin-top: -2px;
        top: 50%;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        -ms-border-radius: 2px;
        border-radius: 2px; }
        header .header-hamburger .open-menu span:before, header .header-hamburger .open-menu span:after {
          content: "";
          right: 0;
          height: 4px;
          background: #1a1a1a;
          position: absolute;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          -ms-border-radius: 2px;
          border-radius: 2px; }
        header .header-hamburger .open-menu span:before {
          top: -10px;
          width: 6rem; }
        header .header-hamburger .open-menu span:after {
          bottom: -10px;
          width: 4rem; }
    header .header.open .header-hamburger .open-menu span {
      background: transparent; }
      header .header.open .header-hamburger .open-menu span:before {
        top: 0;
        width: 5rem;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg); }
      header .header.open .header-hamburger .open-menu span:after {
        top: 0;
        width: 5rem;
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg); }
    header .header.open .header-menu {
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
      -ms-transform: translateX(0px);
      -o-transform: translateX(0px);
      transform: translateX(0px); }

.EditMode header,
.DesignMode header {
  position: static; }

footer {
  padding: 40px 0;
  background: #1a1a1a;
  color: #fff; }
  footer .footer {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between; }
    @media (max-width: 767px) {
      footer .footer {
        flex-direction: column;
        justify-content: center; } }
    @media (max-width: 767px) {
      footer .footer .footer-column {
        order: 1; } }
    @media (max-width: 767px) {
      footer .footer .footer-column:first-child {
        order: 2;
        text-align: center;
        margin-top: 10rem; } }
    footer .footer .footer-column:first-child .ref-row {
      padding-bottom: 6rem; }
      footer .footer .footer-column:first-child .ref-row ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center; }
      footer .footer .footer-column:first-child .ref-row a {
        padding-right: 6.5rem;
        color: #fff; }
        @media (max-width: 767px) {
          footer .footer .footer-column:first-child .ref-row a {
            padding: 0 2rem; } }
        footer .footer .footer-column:first-child .ref-row a:hover, footer .footer .footer-column:first-child .ref-row a:focus {
          color: #9280ac; }

.is-md {
  display: none; }
  @media (min-width: 768px) {
    .is-md {
      display: block; } }

.is-lg {
  display: none; }
  @media (min-width: 992px) {
    .is-lg {
      display: block; } }

.is-xl {
  display: none; }
  @media (min-width: 1200px) {
    .is-xl {
      display: block; } }

.is-xxl {
  display: none; }
  @media (min-width: 1400px) {
    .is-xxl {
      display: block; } }

/*
@font-face {
  font-family: 'Helvetica Neue LT Pro - Lt';
  src: url('../fonts/HelveticaNeueLTPro-Lt.eot');
  src: url('../fonts/HelveticaNeueLTPro-Lt.woff2') format('woff2'),
       url('../fonts/HelveticaNeueLTPro-Lt.woff') format('woff'),
       url('../fonts/HelveticaNeueLTPro-Lt.ttf')  format('truetype')
}

@font-face {
  font-family: 'Helvetica Neue LT Pro - Bd';
  src: url('../fonts/HelveticaNeueLTPro-Bd.eot');
  src: url('../fonts/HelveticaNeueLTPro-Bd.woff2') format('woff2'),
       url('../fonts/HelveticaNeueLTPro-Bd.woff') format('woff'),
       url('../fonts/HelveticaNeueLTPro-Bd.ttf')  format('truetype')
}*/
.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
  font-family: 'Core-icons' !important; }

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.helper-4k {
  max-width: 200rem;
  margin: auto; }

html {
  font-size: 5px; }
  @media (min-width: 480px) {
    html {
      font-size: 5.5px; } }
  @media (min-width: 545px) {
    html {
      font-size: 6px; } }
  @media (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media (min-width: 1200px) {
    html {
      font-size: 9px; } }
  @media (min-width: 1400px) {
    html {
      font-size: 10px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (-o-min-device-pixel-ratio: 5 / 4), only screen and (min-resolution: 120dpi), only screen and (min-resolution: 1.25dppx) {
  html {
    font-size: 5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 480px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 480px), only screen and (min-resolution: 120dpi) and (min-width: 480px), only screen and (min-resolution: 1.25dppx) and (min-width: 480px) {
    html {
      font-size: 5.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 545px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 545px), only screen and (min-resolution: 120dpi) and (min-width: 545px), only screen and (min-resolution: 1.25dppx) and (min-width: 545px) {
    html {
      font-size: 6px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 768px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 768px), only screen and (min-resolution: 120dpi) and (min-width: 768px), only screen and (min-resolution: 1.25dppx) and (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 992px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 992px), only screen and (min-resolution: 120dpi) and (min-width: 992px), only screen and (min-resolution: 1.25dppx) and (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1200px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1200px), only screen and (min-resolution: 120dpi) and (min-width: 1200px), only screen and (min-resolution: 1.25dppx) and (min-width: 1200px) {
    html {
      font-size: 7.2px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1400px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1400px), only screen and (min-resolution: 120dpi) and (min-width: 1400px), only screen and (min-resolution: 1.25dppx) and (min-width: 1400px) {
    html {
      font-size: 8px; } }

form,
html,
body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  height: 100%;
  color: #1a1a1a; }

@media (min-width: 320px) {
  body {
    font-size: 2.8rem; } }

@media (min-width: 480px) {
  body {
    font-size: 2.54545rem; } }

@media (min-width: 545px) {
  body {
    font-size: 2.33333rem; } }

@media (min-width: 768px) {
  body {
    font-size: 1.9rem; } }

img {
  max-width: 100%;
  height: auto !important; }

@media (min-width: 320px) {
  .pt-5 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-5 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-5 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-5 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-5 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-5 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-5 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-5 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-5 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-5 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-5 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-5 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-5 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-5 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-5 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-5 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-5 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-5 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-5 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-5 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-5 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-5 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-5 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-5 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-5 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-5 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-5 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-5 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-10 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-10 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-10 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-10 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-10 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-10 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-10 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-10 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-10 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-10 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-10 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-10 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-10 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-10 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-10 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-10 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-10 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-10 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-10 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-10 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-10 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-10 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-10 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-10 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-10 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-10 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-10 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-10 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-15 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-15 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-15 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-15 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-15 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-15 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-15 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-15 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-15 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-15 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-15 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-15 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-15 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-15 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-15 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-15 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-15 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-15 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-15 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-15 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-15 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-15 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-15 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-15 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-15 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-15 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-15 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-15 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-20 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-20 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-20 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-20 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-20 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-20 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-20 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-20 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-20 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-20 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-20 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-20 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-20 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-20 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-20 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-20 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-20 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-20 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-20 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-20 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-20 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-20 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-20 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-20 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-20 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-20 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-20 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-20 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-25 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-25 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-25 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-25 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-25 {
    padding-top: 2.5rem; } }

@media (min-width: 320px) {
  .pb-25 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-25 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-25 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-25 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-25 {
    padding-bottom: 2.5rem; } }

@media (min-width: 320px) {
  .mt-25 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-25 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-25 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-25 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-25 {
    margin-top: 2.5rem; } }

@media (min-width: 320px) {
  .mb-25 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-25 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-25 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-25 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-25 {
    margin-bottom: 2.5rem; } }

@media (min-width: 320px) {
  .pt-30 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-30 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-30 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-30 {
    padding-top: 3rem; } }

@media (min-width: 320px) {
  .pb-30 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-30 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-30 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-30 {
    padding-bottom: 3rem; } }

@media (min-width: 320px) {
  .mt-30 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-30 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-30 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-30 {
    margin-top: 3rem; } }

@media (min-width: 320px) {
  .mb-30 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-30 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-30 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-30 {
    margin-bottom: 3rem; } }

@media (min-width: 320px) {
  .pt-35 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-35 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-35 {
    padding-top: 3.5rem; } }

@media (min-width: 320px) {
  .pb-35 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-35 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-35 {
    padding-bottom: 3.5rem; } }

@media (min-width: 320px) {
  .mt-35 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-35 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-35 {
    margin-top: 3.5rem; } }

@media (min-width: 320px) {
  .mb-35 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-35 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-35 {
    margin-bottom: 3.5rem; } }

@media (min-width: 320px) {
  .pt-40 {
    padding-top: 4rem; } }

@media (min-width: 320px) {
  .pb-40 {
    padding-bottom: 4rem; } }

@media (min-width: 320px) {
  .mt-40 {
    margin-top: 4rem; } }

@media (min-width: 320px) {
  .mb-40 {
    margin-bottom: 4rem; } }

@media (min-width: 320px) {
  .pt-45 {
    padding-top: 4.5rem; } }

@media (min-width: 320px) {
  .pb-45 {
    padding-bottom: 4.5rem; } }

@media (min-width: 320px) {
  .mt-45 {
    margin-top: 4.5rem; } }

@media (min-width: 320px) {
  .mb-45 {
    margin-bottom: 4.5rem; } }

@media (min-width: 320px) {
  .pt-50 {
    padding-top: 5rem; } }

@media (min-width: 320px) {
  .pb-50 {
    padding-bottom: 5rem; } }

@media (min-width: 320px) {
  .mt-50 {
    margin-top: 5rem; } }

@media (min-width: 320px) {
  .mb-50 {
    margin-bottom: 5rem; } }

@media (min-width: 320px) {
  .pt-55 {
    padding-top: 5.5rem; } }

@media (min-width: 320px) {
  .pb-55 {
    padding-bottom: 5.5rem; } }

@media (min-width: 320px) {
  .mt-55 {
    margin-top: 5.5rem; } }

@media (min-width: 320px) {
  .mb-55 {
    margin-bottom: 5.5rem; } }

@media (min-width: 320px) {
  .pt-60 {
    padding-top: 6rem; } }

@media (min-width: 320px) {
  .pb-60 {
    padding-bottom: 6rem; } }

@media (min-width: 320px) {
  .mt-60 {
    margin-top: 6rem; } }

@media (min-width: 320px) {
  .mb-60 {
    margin-bottom: 6rem; } }

@media (min-width: 320px) {
  .pt-65 {
    padding-top: 6.5rem; } }

@media (min-width: 320px) {
  .pb-65 {
    padding-bottom: 6.5rem; } }

@media (min-width: 320px) {
  .mt-65 {
    margin-top: 6.5rem; } }

@media (min-width: 320px) {
  .mb-65 {
    margin-bottom: 6.5rem; } }

@media (min-width: 320px) {
  .pt-70 {
    padding-top: 7rem; } }

@media (min-width: 320px) {
  .pb-70 {
    padding-bottom: 7rem; } }

@media (min-width: 320px) {
  .mt-70 {
    margin-top: 7rem; } }

@media (min-width: 320px) {
  .mb-70 {
    margin-bottom: 7rem; } }

@media (min-width: 320px) {
  .pt-75 {
    padding-top: 7.5rem; } }

@media (min-width: 320px) {
  .pb-75 {
    padding-bottom: 7.5rem; } }

@media (min-width: 320px) {
  .mt-75 {
    margin-top: 7.5rem; } }

@media (min-width: 320px) {
  .mb-75 {
    margin-bottom: 7.5rem; } }

@media (min-width: 320px) {
  .pt-80 {
    padding-top: 8rem; } }

@media (min-width: 320px) {
  .pb-80 {
    padding-bottom: 8rem; } }

@media (min-width: 320px) {
  .mt-80 {
    margin-top: 8rem; } }

@media (min-width: 320px) {
  .mb-80 {
    margin-bottom: 8rem; } }

@media (min-width: 320px) {
  .pt-85 {
    padding-top: 8.5rem; } }

@media (min-width: 320px) {
  .pb-85 {
    padding-bottom: 8.5rem; } }

@media (min-width: 320px) {
  .mt-85 {
    margin-top: 8.5rem; } }

@media (min-width: 320px) {
  .mb-85 {
    margin-bottom: 8.5rem; } }

@media (min-width: 320px) {
  .pt-90 {
    padding-top: 9rem; } }

@media (min-width: 320px) {
  .pb-90 {
    padding-bottom: 9rem; } }

@media (min-width: 320px) {
  .mt-90 {
    margin-top: 9rem; } }

@media (min-width: 320px) {
  .mb-90 {
    margin-bottom: 9rem; } }

@media (min-width: 320px) {
  .pt-95 {
    padding-top: 9.5rem; } }

@media (min-width: 320px) {
  .pb-95 {
    padding-bottom: 9.5rem; } }

@media (min-width: 320px) {
  .mt-95 {
    margin-top: 9.5rem; } }

@media (min-width: 320px) {
  .mb-95 {
    margin-bottom: 9.5rem; } }

@media (min-width: 320px) {
  .pt-100 {
    padding-top: 10rem; } }

@media (min-width: 320px) {
  .pb-100 {
    padding-bottom: 10rem; } }

@media (min-width: 320px) {
  .mt-100 {
    margin-top: 10rem; } }

@media (min-width: 320px) {
  .mb-100 {
    margin-bottom: 10rem; } }

h1,
.h1 {
  font-weight: 700; }
  @media (min-width: 320px) {
    h1,
    .h1 {
      font-size: 9rem; } }
  @media (min-width: 320px) {
    h1,
    .h1 {
      line-height: 14.4rem; } }
  @media (min-width: 480px) {
    h1,
    .h1 {
      line-height: 13.09091rem; } }
  @media (min-width: 545px) {
    h1,
    .h1 {
      line-height: 12rem; } }
  @media (min-width: 768px) {
    h1,
    .h1 {
      line-height: 10.2rem; } }

h2,
.h2 {
  font-weight: 700;
  padding: 0 0 3rem 0;
  margin: 0; }
  @media (min-width: 320px) {
    h2,
    .h2 {
      font-size: 7.2rem; } }
  @media (min-width: 480px) {
    h2,
    .h2 {
      font-size: 6.54545rem; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      font-size: 6rem; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      font-size: 5rem; } }
  @media (min-width: 320px) {
    h2,
    .h2 {
      line-height: 8rem; } }
  @media (min-width: 480px) {
    h2,
    .h2 {
      line-height: 7.27273rem; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      line-height: 6.66667rem; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      line-height: 5.6rem; } }

h3,
.h3 {
  font-weight: 700;
  padding: 0 0 3rem 0;
  margin: 0; }
  @media (min-width: 320px) {
    h3,
    .h3 {
      font-size: 4.2rem; } }
  @media (min-width: 480px) {
    h3,
    .h3 {
      font-size: 3.81818rem; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      font-size: 3.5rem; } }
  @media (min-width: 768px) {
    h3,
    .h3 {
      font-size: 3rem; } }
  @media (min-width: 320px) {
    h3,
    .h3 {
      line-height: 4.6rem; } }
  @media (min-width: 480px) {
    h3,
    .h3 {
      line-height: 4.18182rem; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      line-height: 3.83333rem; } }
  @media (min-width: 768px) {
    h3,
    .h3 {
      line-height: 3.3rem; } }

h4,
.h4 {
  font-weight: 700; }
  @media (min-width: 320px) {
    h4,
    .h4 {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    h4,
    .h4 {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    h4,
    .h4 {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    h4,
    .h4 {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    h4,
    .h4 {
      font-size: 2.1rem; } }
  @media (min-width: 320px) {
    h4,
    .h4 {
      line-height: 3.6rem; } }
  @media (min-width: 480px) {
    h4,
    .h4 {
      line-height: 3.27273rem; } }
  @media (min-width: 545px) {
    h4,
    .h4 {
      line-height: 3rem; } }
  @media (min-width: 768px) {
    h4,
    .h4 {
      line-height: 2.4rem; } }
  @media (min-width: 992px) {
    h4,
    .h4 {
      line-height: 2.25rem; } }
  @media (min-width: 1200px) {
    h4,
    .h4 {
      line-height: 2.2rem; } }

@media (min-width: 320px) {
  big,
  .perex {
    font-size: 3.2rem; } }

@media (min-width: 480px) {
  big,
  .perex {
    font-size: 2.90909rem; } }

@media (min-width: 545px) {
  big,
  .perex {
    font-size: 2.66667rem; } }

@media (min-width: 768px) {
  big,
  .perex {
    font-size: 2.13333rem; } }

@media (min-width: 992px) {
  big,
  .perex {
    font-size: 2rem; } }

@media (min-width: 320px) {
  big,
  .perex {
    line-height: 3.6rem; } }

@media (min-width: 480px) {
  big,
  .perex {
    line-height: 3.27273rem; } }

@media (min-width: 545px) {
  big,
  .perex {
    line-height: 3rem; } }

@media (min-width: 768px) {
  big,
  .perex {
    line-height: 2.4rem; } }

@media (min-width: 992px) {
  big,
  .perex {
    line-height: 2.25rem; } }

@media (min-width: 1200px) {
  big,
  .perex {
    line-height: 2.2rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    font-size: 2.8rem; } }

@media (min-width: 480px) {
  small,
  .text-small {
    font-size: 2.54545rem; } }

@media (min-width: 545px) {
  small,
  .text-small {
    font-size: 2.33333rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    font-size: 1.86667rem; } }

@media (min-width: 992px) {
  small,
  .text-small {
    font-size: 1.75rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    font-size: 1.55556rem; } }

@media (min-width: 1400px) {
  small,
  .text-small {
    font-size: 1.5rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    line-height: 3.2rem; } }

@media (min-width: 480px) {
  small,
  .text-small {
    line-height: 2.90909rem; } }

@media (min-width: 545px) {
  small,
  .text-small {
    line-height: 2.66667rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    line-height: 2.13333rem; } }

@media (min-width: 992px) {
  small,
  .text-small {
    line-height: 2rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    line-height: 1.77778rem; } }

@media (min-width: 1400px) {
  small,
  .text-small {
    line-height: 1.6rem; } }

a {
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  color: #1a1a1a;
  cursor: pointer; }
  a:focus, a:hover {
    color: #3b3eac;
    outline: none;
    text-decoration: none; }

.btn {
  display: inline-block;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  border-radius: 999px;
  border: none;
  padding: 1.5rem 7rem;
  font-weight: 700;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  @media (min-width: 320px) {
    .btn {
      font-size: 2.8rem; } }
  @media (min-width: 480px) {
    .btn {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .btn {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .btn {
      font-size: 1.9rem; } }
  .btn:focus, .btn:hover {
    text-decoration: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-primary {
  background: transparent;
  color: #fff;
  border: 4px solid #fff; }
  .btn-primary:focus, .btn-primary:hover {
    text-decoration: none;
    background: #fff;
    color: #1a1a1a;
    border-color: #fff; }

.btn-default {
  background: transparent;
  color: #1a1a1a;
  border: 4px solid #1a1a1a; }
  .btn-default:focus, .btn-default:hover {
    text-decoration: none;
    background: #1a1a1a;
    color: #fff; }

.image-top-center {
  position: top center !important; }

.image-top-left {
  position: top left !important; }

.image-top-right {
  position: top left !important; }

.image-bottom-center {
  position: bottom center !important; }

.image-bottom-left {
  position: bottom left !important; }

.image-bottom-right {
  position: bottom right !important; }

main {
  min-height: 40rem;
  padding-top: 11rem; }
  main ol {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: my-ol; }
    main ol li {
      position: relative;
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 2rem; }
      main ol li:before {
        content: counter(my-ol) ".";
        counter-increment: my-ol;
        color: #fff;
        position: absolute;
        left: 0;
        top: 3px;
        font-weight: bold; }
  main ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    main ul li {
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 12px;
      position: relative; }
      main ul li:before {
        content: "";
        width: 6px;
        height: 6px;
        background: #fff;
        position: absolute;
        top: 6px;
        left: 0;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }
  main table {
    width: 100%; }
    main table tr {
      border-bottom: 1px solid #f1f1f1; }
      main table tr td {
        padding: 1rem 1.5rem; }
      main table tr:nth-child(even) {
        background: #f8f8f8; }
  main .respo-table table {
    width: 100%; }
    main .respo-table table thead {
      display: none; }
      @media (min-width: 768px) {
        main .respo-table table thead {
          display: table-header-group; } }
      main .respo-table table thead tr {
        border-bottom: 1px solid #f1f1f1; }
        main .respo-table table thead tr th {
          font-weight: bold;
          padding: 1rem 1.5rem; }
    main .respo-table table tbody tr {
      display: block;
      border: 1px solid #f1f1f1;
      border-bottom: none;
      margin: 0 0 2rem 0; }
      main .respo-table table tbody tr:nth-child(even) {
        background: #f8f8f8; }
      @media (min-width: 768px) {
        main .respo-table table tbody tr {
          display: table-row;
          margin: 0;
          border: none;
          border-bottom: 1px solid #f1f1f1; } }
      main .respo-table table tbody tr td {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: space-between;
        -moz-box-pack: space-between;
        -ms-flex-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #f1f1f1; }
        @media (min-width: 768px) {
          main .respo-table table tbody tr td {
            display: table-cell;
            border: none;
            width: auto; } }
        main .respo-table table tbody tr td:before {
          content: attr(data-th);
          display: block;
          text-align: left;
          font-family: "Roboto", sans-serif !important;
          margin: 0 1.5rem 0 0; }
          @media (min-width: 768px) {
            main .respo-table table tbody tr td:before {
              display: none; } }

label {
  display: block;
  margin: 1rem 0 0.5rem 0;
  font-size: 2.8rem; }
  @media (min-width: 480px) {
    label {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    label {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    label {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    label {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    label {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    label {
      font-size: 1.4rem; } }

.form-control,
textarea,
input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 0 1.5rem;
  border: 1px solid #f1f1f1;
  background: #f1f1f1;
  color: #1a1a1a;
  font-size: 2.8rem; }
  @media (min-width: 320px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      height: 8rem; } }
  @media (min-width: 480px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      height: 7.27273rem; } }
  @media (min-width: 545px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      height: 6.66667rem; } }
  @media (min-width: 768px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      height: 5.33333rem; } }
  @media (min-width: 992px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      height: 5rem; } }
  @media (min-width: 480px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="password"] {
      font-size: 1.4rem; } }
  .form-control::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  input[type="text"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #000; }
  .form-control:-moz-placeholder,
  textarea:-moz-placeholder,
  input[type="text"]:-moz-placeholder,
  input[type="password"]:-moz-placeholder {
    color: #000; }
  .form-control::-moz-placeholder,
  textarea::-moz-placeholder,
  input[type="text"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder {
    color: #000; }
  .form-control:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  input[type="text"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #000; }
  .form-control:focus,
  textarea:focus,
  input[type="text"]:focus,
  input[type="password"]:focus {
    outline: 0;
    border: 1px solid #9280ac; }

textarea {
  padding: 1.5rem textarea; }
  @media (min-width: 320px) {
    textarea {
      height: 20rem; } }
  @media (min-width: 480px) {
    textarea {
      height: 18.18182rem; } }
  @media (min-width: 545px) {
    textarea {
      height: 16.66667rem; } }
  @media (min-width: 768px) {
    textarea {
      height: 15rem; } }
  textarea :focus {
    border: 1px solid #9280ac; }

input[type="submit"] {
  cursor: pointer; }

.required label:before {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  font-size: 2rem; }
  @media (min-width: 480px) {
    .required label:before {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    .required label:before {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    .required label:before {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    .required label:before {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    .required label:before {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    .required label:before {
      font-size: 1rem; } }

label.required:after {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  color: #9280ac;
  font-size: 2rem; }
  @media (min-width: 480px) {
    label.required:after {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    label.required:after {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    label.required:after {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    label.required:after {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    label.required:after {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    label.required:after {
      font-size: 1rem; } }

.file .uploader-upload {
  display: none; }

.file label {
  display: inline-block;
  padding: 1rem 2rem;
  text-transform: uppercase;
  color: #fff;
  background: #9280ac;
  cursor: pointer;
  font-size: 2.8rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  max-width: 24rem;
  margin: 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  outline: 0 !important; }
  @media (min-width: 480px) {
    .file label {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .file label {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .file label {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .file label {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .file label {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .file label {
      font-size: 1.4rem; } }

.file .file-name {
  padding: 0.5rem;
  background: #b7b7b7;
  color: #1a1a1a; }

.Error select,
.Error .selectric,
.Error input[type="text"],
.Error textarea {
  border: 1px solid #d40511 !important;
  -webkit-box-shadow: inset 0 0 0 1px #d40511;
  -moz-box-shadow: inset 0 0 0 1px #d40511;
  box-shadow: inset 0 0 0 1px #d40511;
  -webkit-animation-name: blink;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-direction: normal;
  -moz-animation-name: blink;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: ease;
  -moz-animation-iteration-count: 2;
  -moz-animation-direction: normal;
  -o-animation-name: blink;
  -o-animation-duration: 1s;
  -o-animation-timing-function: ease;
  -o-animation-iteration-count: 2;
  -o-animation-direction: normal;
  animation-name: blink;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 2;
  animation-direction: normal; }

@-webkit-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-moz-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-ms-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

.EditingFormErrorLabel {
  font-size: 2.8rem;
  color: #d40511;
  display: block;
  margin: 0.5rem 0 0 0; }
  @media (min-width: 480px) {
    .EditingFormErrorLabel {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .EditingFormErrorLabel {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .EditingFormErrorLabel {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .EditingFormErrorLabel {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .EditingFormErrorLabel {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .EditingFormErrorLabel {
      font-size: 1.4rem; } }

.ErrorLabel {
  color: #d40511;
  display: block;
  margin: 0.5rem 0; }

.radio input[type="radio"] {
  display: none; }
  .radio input[type="radio"] + label {
    position: relative;
    padding: 0 0 10px 25px;
    display: inline-block;
    margin: 0; }
    .radio input[type="radio"] + label:before {
      content: "";
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background: #eeeff4;
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      border: 1px solid #eeeff4;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .radio input[type="radio"] + label:after {
      content: "";
      display: block;
      left: 5px;
      top: 8px;
      width: 6px;
      height: 6px;
      background: #9280ac;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .radio input[type="radio"]:checked + label:before {
    border: 1px solid #9280ac; }
  .radio input[type="radio"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.checkbox input[type="checkbox"] {
  display: none; }
  .checkbox input[type="checkbox"] + label {
    position: relative;
    padding: 0 0 10px 25px;
    margin: 0;
    display: inline-block; }
    .checkbox input[type="checkbox"] + label:before {
      content: "";
      width: 16px;
      height: 16px;
      background: #eeeff4;
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      border: 1px solid #eeeff4;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .checkbox input[type="checkbox"] + label:after {
      content: "";
      display: block;
      left: 5px;
      top: 8px;
      width: 6px;
      height: 6px;
      background: #9280ac;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .checkbox input[type="checkbox"]:checked + label:before {
    border: 1px solid #9280ac; }
  .checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.row-form {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -1rem; }

.form-item-100,
.form-item-50,
.form-item-33,
.form-item-25 {
  padding: 0 1rem 1rem 1rem; }

.form-item-100 {
  width: 100%; }

.form-item-50 {
  width: 100%; }
  @media (min-width: 768px) {
    .form-item-50 {
      width: 50%; } }

.form-item-33 {
  width: 100%; }
  @media (min-width: 768px) {
    .form-item-33 {
      width: 33.33%; } }

.form-item-25 {
  width: 100%; }
  @media (min-width: 768px) {
    .form-item-25 {
      width: 25%; } }

.item-background {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-position: center center !important;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }
  .item-background .pux-container {
    width: 100%; }
    @media (max-width: 992px) {
      .item-background .pux-container {
        padding-top: 8rem;
        padding-bottom: 8rem; } }
  @media (min-width: 992px) {
    .item-background.normal-item .item-background-helper-triangle {
      border-top-width: 0px !important; } }
  .item-background-flex {
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    min-height: 62rem; }
  .item-background.reverse-item .item-background-helper {
    background: rgba(26, 26, 26, 0.7);
    left: 0;
    right: auto; }
    .item-background.reverse-item .item-background-helper-triangle {
      position: absolute;
      top: 0;
      right: -35rem;
      left: auto;
      border-right: none;
      width: 0;
      height: 0;
      border-top: 62rem solid transparent;
      border-bottom: 0 solid transparent;
      border-left: 35rem solid rgba(26, 26, 26, 0.7); }
  @media (min-width: 992px) {
    .item-background.reverse-item .item-background-inner {
      float: left; } }
  .item-background-helper {
    position: absolute;
    left: 0;
    right: 0;
    width: 65%;
    height: 100%;
    background: rgba(26, 26, 26, 0.7); }
    @media (min-width: 545px) {
      .item-background-helper {
        width: 50%; } }
    @media (min-width: 992px) {
      .item-background-helper {
        left: auto;
        top: 0;
        right: 0; } }
    .item-background-helper-triangle {
      position: absolute;
      top: 0;
      right: -35rem;
      left: auto;
      border-right: none;
      width: 0;
      height: 0;
      border-top: 62rem solid transparent;
      border-bottom: 0 solid transparent;
      border-left: 35rem solid rgba(26, 26, 26, 0.7); }
      @media (min-width: 992px) {
        .item-background-helper-triangle {
          top: 0;
          left: -35rem;
          height: 100% !important;
          border-left: none;
          width: 0;
          height: 0;
          border-top: 0 solid transparent;
          border-bottom: 62rem solid transparent;
          border-right: 35rem solid rgba(26, 26, 26, 0.7); } }
  .item-background-inner {
    height: 100%;
    position: relative;
    z-index: 2;
    color: #fff;
    max-width: 450px;
    margin: auto; }
    @media (min-width: 320px) {
      .item-background-inner {
        line-height: 3.6rem; } }
    @media (min-width: 480px) {
      .item-background-inner {
        line-height: 3.27273rem; } }
    @media (min-width: 545px) {
      .item-background-inner {
        line-height: 3rem; } }
    @media (min-width: 768px) {
      .item-background-inner {
        line-height: 2.4rem; } }
    @media (min-width: 992px) {
      .item-background-inner {
        line-height: 2.25rem; } }
    @media (min-width: 1200px) {
      .item-background-inner {
        line-height: 2.2rem; } }
    @media (max-width: 992px) {
      .item-background-inner {
        background: rgba(0, 0, 0, 0.58);
        padding: 30px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        border-radius: 20px; } }
    @media (min-width: 768px) {
      .item-background-inner {
        padding: 30px 70px; } }
    @media (min-width: 992px) {
      .item-background-inner {
        padding: 5rem 0;
        width: 50%;
        max-width: 100%;
        margin: 0;
        float: right; } }
    .item-background-inner h2 {
      color: #fff;
      margin-bottom: 3rem; }
    .item-background-inner .download-btns {
      margin-top: 4rem;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
      .item-background-inner .download-btns .btn-download {
        padding: 0;
        margin-left: 1rem;
        height: 5.8rem;
        width: 5.8rem;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center; }
        .item-background-inner .download-btns .btn-download:before {
          font-size: 5.8rem;
          content: "\e900";
          color: #fff;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
        .item-background-inner .download-btns .btn-download:hover:before, .item-background-inner .download-btns .btn-download:focus:before {
          -webkit-transform: scale(1.05);
          -moz-transform: scale(1.05);
          -ms-transform: scale(1.05);
          -o-transform: scale(1.05);
          transform: scale(1.05); }

main .item-background table tr {
  background: none !important;
  border: none !important; }
  main .item-background table tr td {
    vertical-align: top;
    padding: 0.5rem 0; }
    main .item-background table tr td:first-child {
      font-weight: bold; }

.about-container {
  padding: 9rem 0 10rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .about-container .about-column {
    width: 100%; }
    @media (min-width: 992px) {
      .about-container .about-column {
        width: 50%; } }
    .about-container .about-column-text {
      text-align: center; }
      @media (min-width: 320px) {
        .about-container .about-column-text {
          line-height: 3.2rem; } }
      @media (min-width: 480px) {
        .about-container .about-column-text {
          line-height: 2.90909rem; } }
      @media (min-width: 545px) {
        .about-container .about-column-text {
          line-height: 2.66667rem; } }
      @media (min-width: 768px) {
        .about-container .about-column-text {
          line-height: 2.2rem; } }
      @media (min-width: 992px) {
        .about-container .about-column-text {
          padding-right: 12rem;
          text-align: left; } }
      .about-container .about-column-text .btn {
        margin-top: 3rem; }
    .about-container .about-column .small-gallery {
      width: 100%;
      margin-top: 8rem;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      @media (min-width: 992px) {
        .about-container .about-column .small-gallery {
          margin-top: 0; } }
      .about-container .about-column .small-gallery .small-gallery-item {
        box-shadow: 0 0 109px rgba(0, 0, 0, 0.17);
        position: relative;
        width: 50%;
        height: 30rem;
        overflow: hidden; }
        @media (min-width: 992px) {
          .about-container .about-column .small-gallery .small-gallery-item {
            height: 20rem; } }
        .about-container .about-column .small-gallery .small-gallery-item:hover .small-gallery-item-bgr, .about-container .about-column .small-gallery .small-gallery-item:focus .small-gallery-item-bgr {
          -webkit-transform: scale(1.2);
          -moz-transform: scale(1.2);
          -ms-transform: scale(1.2);
          -o-transform: scale(1.2);
          transform: scale(1.2); }
        .about-container .about-column .small-gallery .small-gallery-item-bgr {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          background-position: center !important;
          background-size: cover !important;
          background-repeat: no-repeat !important; }
        .about-container .about-column .small-gallery .small-gallery-item:nth-child(1) {
          margin-top: 10rem; }
        .about-container .about-column .small-gallery .small-gallery-item:nth-child(4) {
          margin-top: -10rem; }

.product-container {
  padding: 0rem 0 10rem; }
  .product-container .row {
    padding: 0 3.5rem;
    width: 100%;
    margin: 0 -.5rem;
    margin-top: 3.5rem; }
    .product-container .row [class^="col-"] {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      padding: 0.5rem; }
      .product-container .row [class^="col-"]:hover .product-item, .product-container .row [class^="col-"]:focus .product-item {
        background: #9280ac;
        color: #fff; }
        .product-container .row [class^="col-"]:hover .product-item:after, .product-container .row [class^="col-"]:hover .product-item:before, .product-container .row [class^="col-"]:focus .product-item:after, .product-container .row [class^="col-"]:focus .product-item:before {
          opacity: 0.05;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=5)";
          filter: alpha(opacity=5); }
    .product-container .row .product-item {
      width: 100%;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: space-between;
      -moz-box-pack: space-between;
      -ms-flex-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      background: #eeeeee;
      padding-left: 5rem;
      min-height: 27rem;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      position: relative;
      overflow: hidden; }
      @media (min-width: 768px) {
        .product-container .row .product-item {
          min-height: 19rem; } }
      @media (min-width: 1200px) {
        .product-container .row .product-item {
          padding-left: 3rem; } }
      @media (min-width: 1400px) {
        .product-container .row .product-item {
          padding-left: 5rem; } }
      @media (min-width: 1600px) {
        .product-container .row .product-item {
          padding-left: 8rem; } }
      .product-container .row .product-item:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 9.5rem;
        height: 100%;
        background: #000;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
      .product-container .row .product-item:after {
        content: "";
        position: absolute;
        top: 0;
        left: 9.5rem;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 30rem 0 0 22.5rem;
        border-color: transparent transparent transparent #000;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
      .product-container .row .product-item-name {
        position: relative;
        z-index: 2;
        max-width: 50%;
        padding: 6rem 3rem 6rem 0; }
      .product-container .row .product-item-image {
        max-width: 45%;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
        @media (min-width: 1200px) {
          .product-container .row .product-item-image {
            max-width: 30%; } }
        @media (min-width: 1400px) {
          .product-container .row .product-item-image {
            max-width: 45%; } }
      .product-container .row .product-item:hover .product-item-image, .product-container .row .product-item:focus .product-item-image {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2); }

.text-box,
.contact-box {
  max-width: 550px;
  padding: 0 0 3rem 0;
  text-align: center;
  margin: auto; }
  .text-box h1,
  .text-box h2,
  .contact-box h1,
  .contact-box h2 {
    margin: 0;
    padding: 0 0 3rem 0; }
    @media (min-width: 320px) {
      .text-box h1,
      .text-box h2,
      .contact-box h1,
      .contact-box h2 {
        font-size: 7.2rem; } }
    @media (min-width: 480px) {
      .text-box h1,
      .text-box h2,
      .contact-box h1,
      .contact-box h2 {
        font-size: 6.54545rem; } }
    @media (min-width: 545px) {
      .text-box h1,
      .text-box h2,
      .contact-box h1,
      .contact-box h2 {
        font-size: 6rem; } }
    @media (min-width: 768px) {
      .text-box h1,
      .text-box h2,
      .contact-box h1,
      .contact-box h2 {
        font-size: 5rem; } }
    @media (min-width: 320px) {
      .text-box h1,
      .text-box h2,
      .contact-box h1,
      .contact-box h2 {
        line-height: 8rem; } }
    @media (min-width: 480px) {
      .text-box h1,
      .text-box h2,
      .contact-box h1,
      .contact-box h2 {
        line-height: 7.27273rem; } }
    @media (min-width: 545px) {
      .text-box h1,
      .text-box h2,
      .contact-box h1,
      .contact-box h2 {
        line-height: 6.66667rem; } }
    @media (min-width: 768px) {
      .text-box h1,
      .text-box h2,
      .contact-box h1,
      .contact-box h2 {
        line-height: 5.6rem; } }
  @media (min-width: 1200px) {
    .text-box,
    .contact-box {
      max-width: 800px; } }

.text-box {
  padding: 6rem 0 0 0; }
  @media (min-width: 1200px) {
    .text-box {
      max-width: 800px; } }

.contact-box {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 1.5rem 0 5rem; }
  .contact-box-column {
    width: 100%;
    padding-bottom: 3rem; }
    @media (min-width: 768px) {
      .contact-box-column {
        width: 50%;
        padding-left: 1rem;
        padding-bottom: 0;
        text-align: left; } }

.product-detail {
  position: relative;
  padding: 2rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: flex-end;
  -moz-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end; }
  @media (max-width: 767px) {
    .product-detail {
      margin-bottom: 20rem; } }
  .product-detail-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    overflow: hidden; }
    .product-detail-image:before {
      position: absolute;
      content: "";
      display: none;
      top: 0;
      right: 20rem;
      width: 37rem;
      height: 100%; }
      @media (min-width: 768px) {
        .product-detail-image:before {
          display: block;
          border-style: solid;
          border-width: 0 40rem 80rem 0;
          border-color: transparent rgba(0, 0, 0, 0.25) transparent transparent; } }
    .product-detail-image:after {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: rgba(26, 26, 26, 0.25); }
      @media (min-width: 768px) {
        .product-detail-image:after {
          width: 20rem; } }
  .product-detail-back {
    position: absolute;
    top: 2rem;
    left: 2rem;
    color: white;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    font-weight: 400; }
    @media (min-width: 320px) {
      .product-detail-back {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .product-detail-back {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .product-detail-back {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .product-detail-back {
        font-size: 2.13333rem; } }
    @media (min-width: 992px) {
      .product-detail-back {
        font-size: 2rem; } }
    .product-detail-back:before {
      content: "\e903";
      margin-right: 7px;
      display: block;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .product-detail-back:hover, .product-detail-back:focus {
      color: white; }
      .product-detail-back:hover:before, .product-detail-back:focus:before {
        -webkit-transform: translateX(-5px);
        -moz-transform: translateX(-5px);
        -ms-transform: translateX(-5px);
        -o-transform: translateX(-5px);
        transform: translateX(-5px); }
  .product-detail-content {
    position: relative;
    z-index: 2;
    margin-right: 0;
    background: #fff;
    padding: 4rem 6rem 4rem 9rem;
    box-shadow: 0 4px 135px 1px rgba(0, 0, 0, 0.25); }
    @media (max-width: 767px) {
      .product-detail-content {
        bottom: -20rem;
        width: 100%; } }
    @media (min-width: 1200px) {
      .product-detail-content {
        right: -14rem; } }
    .product-detail-content h2 {
      padding-bottom: 3rem;
      padding-left: 2rem; }
      @media (min-width: 320px) {
        .product-detail-content h2 {
          font-size: 6rem; } }
      @media (min-width: 480px) {
        .product-detail-content h2 {
          font-size: 5.45455rem; } }
      @media (min-width: 545px) {
        .product-detail-content h2 {
          font-size: 5rem; } }
      @media (min-width: 768px) {
        .product-detail-content h2 {
          font-size: 4.1rem; } }
    .product-detail-content-brand {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      min-height: 6rem;
      min-width: 30rem;
      border-bottom: 1px solid #d7d7d7; }
      .product-detail-content-brand:last-child {
        border: none; }
      .product-detail-content-brand-logo {
        text-align: center;
        width: 7rem; }
      .product-detail-content-brand-name {
        display: block;
        color: #a8a7a7;
        margin-left: 20px; }
        @media (min-width: 320px) {
          .product-detail-content-brand-name {
            font-size: 3.8rem; } }
        @media (min-width: 480px) {
          .product-detail-content-brand-name {
            font-size: 3.45455rem; } }
        @media (min-width: 545px) {
          .product-detail-content-brand-name {
            font-size: 3.16667rem; } }
        @media (min-width: 768px) {
          .product-detail-content-brand-name {
            font-size: 2.53333rem; } }
        @media (min-width: 992px) {
          .product-detail-content-brand-name {
            font-size: 2.375rem; } }
        @media (min-width: 1200px) {
          .product-detail-content-brand-name {
            font-size: 2.3rem; } }
        @media (max-width: 767px) {
          .product-detail-content-brand-name {
            padding: 3rem 0; } }
      .product-detail-content-brand:hover a, .product-detail-content-brand:focus a {
        color: #9280ac; }

.gallery-container {
  padding: 5rem 0 4rem; }
  .gallery-container .row {
    margin: 3.5rem 3.5rem 0; }
    .gallery-container .row [class^="col-"] {
      padding: 0;
      margin: 0; }
    .gallery-container .row .gallery-item {
      position: relative;
      width: 100%;
      height: 40rem;
      overflow: hidden; }
      @media (min-width: 992px) {
        .gallery-container .row .gallery-item {
          height: 30rem; } }
      .gallery-container .row .gallery-item:hover .gallery-item-bgr, .gallery-container .row .gallery-item:focus .gallery-item-bgr {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2); }
      .gallery-container .row .gallery-item-bgr {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms;
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important; }

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 0; }
  .selectric-wrapper:focus {
    background: #eeeff4;
    color: #c7c7c7;
    border-bottom: 2px solid #c7c7c7; }

.selectric-responsive {
  width: 100%; }

.selectric {
  border-radius: 0px;
  background: #eeeff4;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px; }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 60px 0 15px;
    font-size: 15px;
    line-height: 60px;
    color: blue;
    height: 60px;
    user-select: none; }
  .selectric .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;
    line-height: 60px;
    color: #e0e0e0;
    text-align: center;
    font: 0/0 a;
    *font: 20px/60px Lucida Sans Unicode, Arial Unicode MS, Arial;
    transition: all ease 200ms; }
    .selectric .button:after {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 15px;
      content: "\e9b4";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #c7c7c7; }

.selectric-focus .selectric {
  border-color: #e0e0e0; }

.selectric-hover .selectric {
  border-color: #e0e0e0; }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric {
    border-color: #e0e0e0;
    border-bottom: 0px; }
    .selectric-open .selectric .button {
      color: #c7c7c7;
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .selectric-open .selectric-items {
    display: block; }

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #c7c7c7;
  border: 1px solid #e0e0e0;
  border-top: 0;
  z-index: -1;
  box-shadow: 0 0 10px -6px; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    min-height: 20px; }
  .selectric-items li {
    display: block;
    padding: 10px;
    color: blue;
    background: #e4e4eb;
    cursor: pointer;
    transition: all ease 200ms; }
    .selectric-items li:before {
      display: none; }
    .selectric-items li.selected {
      color: blue; }
    .selectric-items li.highlighted {
      color: blue; }
    .selectric-items li:hover {
      background: #eeeff4; }
    .selectric-items li:nth-child(odd) {
      background: #d5d5e0; }
      .selectric-items li:nth-child(odd):hover {
        background: #c0c3d6; }
  .selectric-items .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 10px;
    cursor: default;
    user-select: none;
    background: none;
    color: #444; }
  .selectric-items .selectric-group.disabled li {
    filter: alpha(opacity=100);
    opacity: 1; }
  .selectric-items .selectric-group li {
    padding-left: 25px; }

.slider-init .main-slider {
  width: 100%;
  background-repeat: no-repeat !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }
  .slider-init .main-slider-inner {
    width: 100%;
    padding: 6rem 0 6rem 0;
    min-height: -webkit-calc(100vh - 11rem);
    min-height: -moz-calc(100vh - 11rem);
    min-height: calc(100vh - 11rem);
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    .slider-init .main-slider-inner .pux-container {
      text-align: center; }
      @media (min-width: 1200px) {
        .slider-init .main-slider-inner .pux-container {
          text-align: left;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center; } }
    .slider-init .main-slider-inner-content {
      width: 100%;
      max-width: 75rem;
      margin: auto; }
      @media (min-width: 1200px) {
        .slider-init .main-slider-inner-content {
          margin-left: 0;
          width: 50%; } }
      .slider-init .main-slider-inner-content-text {
        color: #fff; }
        @media (min-width: 320px) {
          .slider-init .main-slider-inner-content-text {
            line-height: 4.4rem; } }
        @media (min-width: 480px) {
          .slider-init .main-slider-inner-content-text {
            line-height: 4rem; } }
        @media (min-width: 545px) {
          .slider-init .main-slider-inner-content-text {
            line-height: 3.66667rem; } }
        @media (min-width: 768px) {
          .slider-init .main-slider-inner-content-text {
            line-height: 3.2rem; } }
        .slider-init .main-slider-inner-content-text h1,
        .slider-init .main-slider-inner-content-text h2 {
          color: #fff;
          padding-bottom: 2.5rem; }
      .slider-init .main-slider-inner-content-btn {
        padding: 5rem 0 0 0; }
    .slider-init .main-slider-inner-image {
      margin-top: 5rem; }
      @media (min-width: 1200px) {
        .slider-init .main-slider-inner-image {
          width: 50%; } }
      .slider-init .main-slider-inner-image img {
        object-fit: cover; }
    .slider-init .main-slider-inner .pux-container {
      width: 100%; }

.slider-init .slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -8rem;
  width: 7rem;
  height: 14rem;
  -webkit-border-radius: 0 999px 999px 0;
  -moz-border-radius: 0 999px 999px 0;
  -ms-border-radius: 0 999px 999px 0;
  border-radius: 0 999px 999px 0;
  border: none;
  font-size: 0;
  background: rgba(255, 255, 255, 0.2);
  outline: 0;
  z-index: 22;
  overflow: hidden;
  cursor: pointer; }
  .slider-init .slick-arrow:before {
    font-size: 5.4rem;
    color: #fff; }
  .slider-init .slick-arrow.slick-prev {
    left: 0rem; }
    .slider-init .slick-arrow.slick-prev:before {
      display: block;
      -webkit-transform: translateX(-15%);
      -moz-transform: translateX(-15%);
      -ms-transform: translateX(-15%);
      -o-transform: translateX(-15%);
      transform: translateX(-15%);
      content: ""; }
  .slider-init .slick-arrow.slick-next {
    right: 0rem;
    -webkit-border-radius: 999px 0 0 999px;
    -moz-border-radius: 999px 0 0 999px;
    -ms-border-radius: 999px 0 0 999px;
    border-radius: 999px 0 0 999px; }
    .slider-init .slick-arrow.slick-next:before {
      display: block;
      -webkit-transform: translateX(15%);
      -moz-transform: translateX(15%);
      -ms-transform: translateX(15%);
      -o-transform: translateX(15%);
      transform: translateX(15%);
      content: ""; }

.slider-init .slick-dots {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  top: -6rem;
  left: 0;
  width: 100%;
  max-width: 169rem;
  margin: auto;
  bottom: 8rem;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .slider-init .slick-dots {
      padding-left: 6rem;
      padding-right: 6rem; } }
  .slider-init .slick-dots li {
    margin: 0;
    padding: 0 0.5rem 0 0; }
    .slider-init .slick-dots li:before {
      display: none; }
    .slider-init .slick-dots li:last-child {
      padding: 0; }
    .slider-init .slick-dots li:first-child:last-child {
      display: none; }
    .slider-init .slick-dots li button {
      font-size: 0;
      outline: 0;
      width: 4.8rem;
      height: 4.8rem;
      border: none;
      background: transparent;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      padding: 0;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center; }
      @media (min-width: 480px) {
        .slider-init .slick-dots li button {
          width: 4.36364rem; } }
      @media (min-width: 545px) {
        .slider-init .slick-dots li button {
          width: 4rem; } }
      @media (min-width: 768px) {
        .slider-init .slick-dots li button {
          width: 3.2rem; } }
      @media (min-width: 992px) {
        .slider-init .slick-dots li button {
          width: 3rem; } }
      @media (min-width: 1200px) {
        .slider-init .slick-dots li button {
          width: 2.66667rem; } }
      @media (min-width: 1400px) {
        .slider-init .slick-dots li button {
          width: 2.4rem; } }
      @media (min-width: 480px) {
        .slider-init .slick-dots li button {
          height: 4.36364rem; } }
      @media (min-width: 545px) {
        .slider-init .slick-dots li button {
          height: 4rem; } }
      @media (min-width: 768px) {
        .slider-init .slick-dots li button {
          height: 3.2rem; } }
      @media (min-width: 992px) {
        .slider-init .slick-dots li button {
          height: 3rem; } }
      @media (min-width: 1200px) {
        .slider-init .slick-dots li button {
          height: 2.66667rem; } }
      @media (min-width: 1400px) {
        .slider-init .slick-dots li button {
          height: 2.4rem; } }
      .slider-init .slick-dots li button:before {
        content: "";
        width: 1.8rem;
        height: 1.8rem;
        background: #fff;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }
        @media (min-width: 480px) {
          .slider-init .slick-dots li button:before {
            width: 1.63636rem; } }
        @media (min-width: 545px) {
          .slider-init .slick-dots li button:before {
            width: 1.5rem; } }
        @media (min-width: 768px) {
          .slider-init .slick-dots li button:before {
            width: 1.2rem; } }
        @media (min-width: 992px) {
          .slider-init .slick-dots li button:before {
            width: 1.125rem; } }
        @media (min-width: 1200px) {
          .slider-init .slick-dots li button:before {
            width: 1rem; } }
        @media (min-width: 1400px) {
          .slider-init .slick-dots li button:before {
            width: 0.9rem; } }
        @media (min-width: 480px) {
          .slider-init .slick-dots li button:before {
            height: 1.63636rem; } }
        @media (min-width: 545px) {
          .slider-init .slick-dots li button:before {
            height: 1.5rem; } }
        @media (min-width: 768px) {
          .slider-init .slick-dots li button:before {
            height: 1.2rem; } }
        @media (min-width: 992px) {
          .slider-init .slick-dots li button:before {
            height: 1.125rem; } }
        @media (min-width: 1200px) {
          .slider-init .slick-dots li button:before {
            height: 1rem; } }
        @media (min-width: 1400px) {
          .slider-init .slick-dots li button:before {
            height: 0.9rem; } }
    .slider-init .slick-dots li.slick-active button {
      width: 23px;
      height: 23px;
      border: 4px solid #ffffff; }
      .slider-init .slick-dots li.slick-active button:before {
        background: transparent; }

/*!
 * Datepicker for Bootstrap v1.8.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */
.datepicker {
  padding: 0.4rem;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  -ms-border-radius: 0.4rem;
  border-radius: 0.4rem;
  direction: ltr;
  font-size: 2.4rem; }
  @media (min-width: 480px) {
    .datepicker {
      font-size: 2.18182rem; } }
  @media (min-width: 545px) {
    .datepicker {
      font-size: 2rem; } }
  @media (min-width: 768px) {
    .datepicker {
      font-size: 1.6rem; } }
  @media (min-width: 992px) {
    .datepicker {
      font-size: 1.5rem; } }
  @media (min-width: 1200px) {
    .datepicker {
      font-size: 1.33333rem; } }
  @media (min-width: 1400px) {
    .datepicker {
      font-size: 1.2rem; } }
  .datepicker-inline {
    width: 22rem; }
  .datepicker-rtl {
    direction: rtl; }
    .datepicker-rtl.dropdown-menu {
      left: auto; }
    .datepicker-rtl table tr td span {
      float: right; }
  .datepicker-dropdown {
    top: 0;
    left: 0; }
    .datepicker-dropdown:before {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #999;
      border-top: 0;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      position: absolute; }
    .datepicker-dropdown:after {
      content: '';
      display: inline-block;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #999;
      border-top: 0;
      position: absolute; }

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px; }

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px; }

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px; }

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px; }

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px; }

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px; }

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999; }

.datepicker-dropdown.datepicker-orient-top:after {
  top: -6px;
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff; }

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.datepicker td,
.datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  -ms-border-radius: 0.4rem;
  border-radius: 0.4rem;
  border: none; }

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent; }

.datepicker table tr td.day:hover,
.datepicker table tr td.day.focused {
  background: #eee;
  cursor: pointer; }

.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #999; }

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #999;
  cursor: default; }

.datepicker table tr td.highlighted {
  background: #d9edf7;
  border-radius: 0; }

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #b7b7b7;
  color: #fff;
  background-image: -webkit-linear-gradient(top, #b7b7b7 0%, #9e9e9e 100%);
  background-image: -o-linear-gradient(top, #b7b7b7 0%, #9e9e9e 100%);
  background-image: linear-gradient(to bottom, #b7b7b7 0%, #9e9e9e 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFB7B7B7', endColorstr='#FF9E9E9E', GradientType=0); }

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
  background-color: #fdf59a; }

.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
  background-color: #fbf069 \9; }

.datepicker table tr td.today:hover:hover {
  color: #fff; }

.datepicker table tr td.today.active:hover {
  color: #fff; }

.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
  background: #eee;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
  background-color: #f3d17a;
  background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
  background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
  border-color: #f3e97a #f3e97a #edde34;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled] {
  background-color: #f3e97a; }

.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active {
  background-color: #efe24b \9; }

.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
  background-color: #9e9e9e;
  background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
  background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: linear-gradient(to bottom, #b3b3b3, #808080);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
  border-color: #808080 #808080 #595959;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected.disabled:hover[disabled] {
  background-color: #808080; }

.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active {
  background-color: #666666 \9; }

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-color: #9280ac;
  background-image: -webkit-linear-gradient(top, #9280ac 0%, #786297 100%);
  background-image: -o-linear-gradient(top, #9280ac 0%, #786297 100%);
  background-image: linear-gradient(to bottom, #9280ac 0%, #786297 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF9280AC', endColorstr='#FF786297', GradientType=0);
  color: #fff; }

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
  background-color: #9280ac;
  background-image: -webkit-linear-gradient(top, #9280ac 0%, #786297 100%);
  background-image: -o-linear-gradient(top, #9280ac 0%, #786297 100%);
  background-image: linear-gradient(to bottom, #9280ac 0%, #786297 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF9280AC', endColorstr='#FF786297', GradientType=0);
  color: #fff; }

.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
  background-color: #9280ac;
  background-image: -webkit-linear-gradient(top, #9280ac 0%, #786297 100%);
  background-image: -o-linear-gradient(top, #9280ac 0%, #786297 100%);
  background-image: linear-gradient(to bottom, #9280ac 0%, #786297 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF9280AC', endColorstr='#FF786297', GradientType=0);
  color: #fff; }

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.datepicker table tr td span:hover {
  background-color: #eee; }

.datepicker table tr td span.focused {
  background-color: #9280ac;
  background-image: -webkit-linear-gradient(top, #9280ac 0%, #786297 100%);
  background-image: -o-linear-gradient(top, #9280ac 0%, #786297 100%);
  background-image: linear-gradient(to bottom, #9280ac 0%, #786297 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF9280AC', endColorstr='#FF786297', GradientType=0);
  color: #fff; }

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #999;
  cursor: default; }

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-color: #9280ac;
  background-image: -webkit-linear-gradient(top, #9280ac 0%, #786297 100%);
  background-image: -o-linear-gradient(top, #9280ac 0%, #786297 100%);
  background-image: linear-gradient(to bottom, #9280ac 0%, #786297 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF9280AC', endColorstr='#FF786297', GradientType=0);
  color: #fff; }

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #b7b7b7;
  color: #fff;
  background-image: -webkit-linear-gradient(top, #b7b7b7 0%, #9e9e9e 100%);
  background-image: -o-linear-gradient(top, #b7b7b7 0%, #9e9e9e 100%);
  background-image: linear-gradient(to bottom, #b7b7b7 0%, #9e9e9e 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFB7B7B7', endColorstr='#FF9E9E9E', GradientType=0); }

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
  background-color: #b7b7b7;
  color: #fff;
  background-image: -webkit-linear-gradient(top, #b7b7b7 0%, #9e9e9e 100%);
  background-image: -o-linear-gradient(top, #b7b7b7 0%, #9e9e9e 100%);
  background-image: linear-gradient(to bottom, #b7b7b7 0%, #9e9e9e 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFB7B7B7', endColorstr='#FF9E9E9E', GradientType=0); }

.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #999; }

.datepicker .datepicker-switch {
  width: 145px; }

.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
  cursor: pointer; }

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  background: #eee; }

.datepicker .prev.disabled,
.datepicker .next.disabled {
  visibility: hidden; }

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle; }

.input-append.date .add-on,
.input-prepend.date .add-on {
  cursor: pointer; }

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  margin-top: 3px; }

.input-daterange input {
  text-align: center; }

.input-daterange input:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px; }

.input-daterange input:last-child {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0; }

.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 18px;
  padding: 4px 5px;
  font-weight: normal;
  line-height: 18px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: middle;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px; }

/*# sourceMappingURL=bootstrap-datepicker.css.map */
@media print {
  header,
  footer {
    display: none; } }
