@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.item-background{
  width:100%;
  position: relative;
  overflow: hidden;
  background-position: center center !important;
  background-size: cover !important;
  @include background-size(cover);
  @include flex-block;
  @include flex-direction(row);
  .pux-container{
    width: 100%;
    @media (max-width:992px){
      padding-top: 8rem;
      padding-bottom: 8rem;
    }
  }
  &.normal-item{
    .item-background-helper{
      &-triangle{
        @include media(lg){
          border-top-width: 0px !important;
        }
      }
    }
  }
  &-flex{
    width: 100%;
    @include flex-block;
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    min-height: 62rem;
  }
  &.reverse-item{
    .item-background-helper{
      background: rgba($base-black,0.7);
      left:0;
      right:auto;
      &-triangle{
        position: absolute;
        top:0;
        right:-35rem;
        left:auto;
        border-right:none;
        @include triangle-right(62rem, 0, 35rem,  rgba($base-black,0.7));
      }
    }
    .item-background-inner{
      @include media(lg){
        float: left;
      }
    }
  }
  &-helper{
    position: absolute;
    left:0;
    right:0;
    width:65%;
    height: 100%;
    background: rgba($base-black,0.7);
    @include media(sm){
      width:50%;
    }
    @include media(lg){
      left:auto;
      top:0;
      right:0;
    }
    &-triangle{
      position: absolute;
      top:0;
      right:-35rem;
      left:auto;
      border-right:none;
      @include triangle-right(62rem, 0, 35rem,  rgba($base-black,0.7));
      @include media(lg){
        top:0;
        left:-35rem;
        height: 100% !important;
        border-left: none;
        @include triangle-left(0, 62rem, 35rem,  rgba($base-black,0.7));
      }
    }
  }
  &-inner{
    height: 100%;
    position: relative;
    z-index: 2;
    color: $base-white;
    max-width: 450px;
    margin:auto;
    @include pux-scale-with-min("line-height", 22px, 18px);
    @media (max-width:992px){
      background: rgba(#000,0.58);
      padding: 30px;
      @include border-radius(20px);
    }
    @include media(md){
      padding: 30px 70px;
    }
    @include media(lg){
      padding: 5rem 0;
      width: 50%;
      max-width: 100%;
      margin: 0;
      float: right;
    }
    h2{
      color: $base-white;
      margin-bottom: 3rem;
    }
    .download-btns{
      margin-top: 4rem;
      @include flex-block;
      @include align-items(center);
      .btn-download{
        padding: 0;
        margin-left: 1rem;
        height: 5.8rem;
        width: 5.8rem;
        //background: $base-white;
        //@include border-radius(50%);
        @include flex-block;
        @include align-items(center);
        &:before{
          font-size: 5.8rem;
          content: "\e900";
          color: $base-white;
          @include transition;
        }
        &:hover,
        &:focus{
          &:before{
            @include transform(scale(1.05));
          }
        }
      }
    }
  }
}

main{
  .item-background{
    table{
      tr{
        background: none !important;
        border:none !important;
        td{
          vertical-align: top;
          padding: 0.5rem 0;
          &:first-child{
            font-weight: bold;
          }
        }
      }
    }
  }
}

.about-container{
    padding: 9rem 0 10rem;
    @include flex-block;
    @include align-items(center);
    @include flex-wrap(wrap);
    .about-column{
        width: 100%;
        @include media(lg){
            width: 50%;
        }
        &-text{
            @include pux-scale-with-min("line-height", 22px, 16px);
            text-align: center;
            @include media(lg){
                padding-right: 12rem;
                text-align: left;
            }
            .btn{
              margin-top: 3rem;
            }
        }
        .small-gallery{
            width: 100%;
            margin-top: 8rem;
            @include media(lg){
                margin-top: 0;
            }
            @include flex-block;
            @include align-items(center);
            @include flex-wrap(wrap);
            .small-gallery-item{
                box-shadow: 0 0 109px rgba(0, 0, 0, 0.17);
                position: relative;
                width: 50%;
                height: 30rem;
                overflow: hidden;
                @include media(lg){
                    height: 20rem;
                }
                &:hover,
                &:focus{
                  .small-gallery-item-bgr{
                    @include transform(scale(1.2));
                  }
                }
                &-bgr{
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    @include transition;
                    background-position: center!important;
                    background-size: cover!important;
                    background-repeat: no-repeat!important;
                }
                &:nth-child(1){
                    margin-top: 10rem;
                }
                &:nth-child(4){
                    margin-top: -10rem;
                }
            }
        }
    }
}

.product-container{
  padding: 0rem 0 10rem;
  .row{
    padding: 0 3.5rem;
    width: 100%;
    margin: 0 -.5rem;
    margin-top: 3.5rem;
    [class^="col-"]{
      @include flex-block;
      padding: 0.5rem;
      &:hover,
      &:focus{
        .product-item{
          background: $main-color;
          color: $base-white;
          &:after,&:before{
            @include opacity(0.05);
          }
        }
      }
    }
    .product-item{
      width:100%;
      @include flex-block;
      @include align-items(center);
      @include justify-content(space-between);
      background: #eeeeee;
      padding-left: 5rem;
      min-height: 27rem;
      @include transition;
      position: relative;
      overflow: hidden;
      @include media(md){
        min-height: 19rem;
      }
      @include media(xl){
        padding-left: 3rem;
      }
      @include media(xxl){
        padding-left: 5rem;
      }
      @media(min-width: 1600px){
        padding-left: 8rem;
      }
      &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 9.5rem;
        height: 100%;
        background: #000;
        @include opacity(0);
        @include transition;
      }
      &:after{
        content: "";
        position: absolute;
        top: 0;
        left: 9.5rem;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 30rem 0 0 22.5rem;
        border-color: transparent transparent transparent #000;
        @include opacity(0);
        @include transition;
      }
      &-name{
        position: relative;
        z-index: 2;
        max-width: 50%;
        padding: 6rem 3rem 6rem 0;
      }
      &-image{
        max-width: 45%;
        @include media(xl){
          max-width: 30%;
        }
        @include media(xxl){
          max-width: 45%;
        }
        @include transition;
      }
      &:hover,
      &:focus{
        .product-item-image{
          @include transform(scale(1.2));
        }
      }
    }
  }
}

.text-box,
.contact-box{
  max-width: 550px;
  padding: 0 0 3rem 0;
  text-align: center;
  margin: auto;
  h1,
  h2{
    @include pux-scale-with-min("font-size", 50px, 36px);
    @include pux-scale-with-min("line-height", 56px, 40px);
    margin:0;
    padding:0 0 3rem 0;
  }
  @include media(xl){
    max-width: 800px;
  }
}

.text-box{
  padding: 6rem 0 0 0;
  @include media(xl){
    max-width: 800px;
  }
}

.contact-box{
  @include flex-block;
  @include flex-wrap(wrap);
  padding: 1.5rem 0 5rem;
  &-column{
    width: 100%;
    padding-bottom: 3rem;
    @include media(md){
      width: 50%;
      padding-left: 1rem;
      padding-bottom: 0;
      text-align: left;
    }
  }
}

.product-detail{
  position: relative;
  padding: 2rem;
  @include flex-block;
  @include justify-content(flex-end);
  @media (max-width: 767px){
    margin-bottom: 20rem;
  }
  &-image{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center!important;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    overflow: hidden;
    &:before{
      position: absolute;
      content: "";
      display: none;
      top:0;
      right: 20rem;
      width: 37rem;
      height: 100%;
      @include media(md){
        display: block;
        border-style: solid;
        border-width: 0 40rem 80rem 0;
        border-color: transparent rgba($color: #000000, $alpha: 0.25) transparent transparent;
      }
    }
    &:after{
      position: absolute;
      content: "";
      top:0;
      right: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: $base-black, $alpha: 0.25);
      @include media(md){
        width: 20rem;
      }
    }
  }
  &-back{
    position: absolute;
    top: 2rem;
    left: 2rem;
    color: white;
    @include pux-scale-with-min("font-size", $font-size-perex, $min-font-size-perex);
    @include flex-block;
    @include align-items(center);
    font-weight: 400;
    &:before{
      content: "\e903";
      margin-right: 7px;
      display: block;
      @include transition;
    }
    &:hover,
    &:focus{
      color: white;
      &:before{
        @include transform(translateX(-5px));
      }
    }
  }
  &-content{
    position: relative;
    z-index: 2;
    @media (max-width: 767px){
      bottom: -20rem;
      width: 100%;
    }
    @include media(xl){
      right: -14rem;
    }
    margin-right: 0;
    background: $base-white;
    padding: 4rem 6rem 4rem 9rem;
    box-shadow: 0 4px 135px 1px rgba(0, 0, 0, 0.25);
    h2{
      @include pux-scale-with-min("font-size", 41px, 30px);
      padding-bottom: 3rem;
      padding-left: 2rem;
    }
    &-brand{
      @include flex-block;
      @include align-items(center);
      min-height: 6rem;
      min-width: 30rem;
      border-bottom: 1px solid #d7d7d7;
      &:last-child{
        border: none;
      }
      &-logo{
        text-align: center;
        width: 7rem;
      }
      &-name{
        display: block;
        color: #a8a7a7;
        margin-left: 20px;
        @include pux-scale-with-min("font-size", 23px, 19px);
        @media (max-width: 767px){
          padding: 3rem 0;
        }
      }
      &:hover,
      &:focus{
        a{
          color: $main-color;
        }
      }
    }
  }
}

.gallery-container{
  padding: 5rem 0 4rem;
  .row{
    margin: 3.5rem 3.5rem 0;
    [class^="col-"]{
      padding: 0;
      margin: 0;
    }
    .gallery-item{
      position: relative;
      width: 100%;
      height: 40rem;
      overflow: hidden;
      @include media(lg){
        height: 30rem;
      }
      &:hover,
      &:focus{
        .gallery-item-bgr{
          @include transform(scale(1.2));
        }
      }
      &-bgr{
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include transition;
        background-position: center!important;
        background-size: cover!important;
        background-repeat: no-repeat!important;
      }
    }
  }
}
