
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.pux-container{
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    max-width: $container-max-width;
    &:after{
        display: block;
        content: "";
        clear: both;
    }
    @include media(md){
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }
    @include media(xl){
      padding-left: 26rem;
      padding-right: 26rem;
  }
}

.pux-container{
    .row{
        @include flex-block;
        @include flex-wrap(wrap);
        margin-left: $row-margin-left;
        margin-right: $row-margin-right;
        @include media(md){
          margin-left: $row-margin-left-md;
          margin-right: $row-margin-right-md;
        }
    }
}

.pux-container{
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto{
        padding-left: $col-padding-left;
        padding-right: $col-padding-right;
        @include media(md){
          padding-left: $col-padding-left-md;
          padding-right: $col-padding-right-md;
        }
    }
}

.pux-fluid-container{
    padding-left: $fluid-container-padding-left;
    padding-right: $fluid-container-padding-right;
    @include media(md){
      padding-left: $fluid-container-padding-left-md;
      padding-right: $fluid-container-padding-right-md;
    }
    @include media(xl){
      padding-left: $fluid-container-padding-left-xl;
      padding-right: $fluid-container-padding-right-xl;
    }
}

#homepage{
  header{
    background-color: #f3f3f3;
  }
}

header{
  width: 100%;
  background-color: $base-white;
  position: fixed;
  @include media($menu-break){
    box-shadow: 0 0 38px rgba(0, 0, 0, 0.23);
  }
  z-index: 77;
  &.scrolled{
    .header{
      @include media($menu-break){
        padding: 2rem 0rem;
      }
    }
  }
  .pux-container{
    @media (max-width: 991px){
      padding: 0;
    }

  }
  .header{
    @include flex-block;
    @include align-items(center);
    @include justify-content(space-between);
    box-shadow: 0 0 38px rgba(0, 0, 0, 0.23);
    padding: 4rem 15px;
    position: relative;
    @include transition;
    @include media(md){
      padding: 4rem 6rem;
    }
    @include media($menu-break){
      padding: 4rem 0;
      box-shadow: none;
      z-index: 200;
    }
    &-logo{
      a{
        display:inline-block;
        span{
          display:none;
        }
        img{
          height: 27px !important;
          width: 108px;
          @include media(md){
            height: 54px !important;
            width: 216px;
          }
        }
      }
    }
    &-menu{
      //display:none;
      @include transition;
      @include transform(translateX(1000px));
      position: absolute;
      top:100%;
      z-index: -1;
      right:0;
      background: #f3f3f3;
      width: 240px;
      height: 100vh;
      //@include calc("max-height", "100vh - 5rem");
      @include media(sm){
        width: 320px;
      }
      @include media($menu-break){
        @include transform(translateX(0px));
        display:block;
        position: static;
        background: none;
        width:auto;
        height: auto;
      }
      ul{
        margin:0;
        padding:0;
        list-style: none;
        position: relative;
        z-index: -1;
        text-align: center;
        @include media($menu-break){
          @include flex-block;
          @include align-items(center);
        }
        li{
          display: block;
          position: relative;
          padding: 0 5rem;
          @include media($menu-break){
            position: static;
            margin:0 1rem;
            padding: 0;
          }
          &:last-child{
            a{
              padding-right: 0;
            }
          }
          a{
            display:block;
            padding:0 3.5rem;
            color:$base-black;
            font-weight: 700;
            @media (max-width: 991px){
              border-bottom: 1px solid #b9b9b9;
              padding: 3rem 0;
            }
            &:focus,
            &:hover{
              color:$main-color;
              text-decoration: none;
            }
          }
          &.Highlighted{
            a{
              color:$main-color;
            }
          }
          ul{
            background: #f3f3f3;
            display: none;
            width:100%;
            @include opacity(1);
            visibility: visible;
            @include transition;
            @include media($menu-break){
              position: absolute;
              width:20rem;
              visibility: hidden;
              @include opacity(0);
              display: block;
            }
            li{
              a{
                &:focus,
                &:hover{

                }
              }
            }
          }
          &:hover{
            & > ul {
              visibility: visible;
              @include opacity(1);
            }
          }
          &.open{
            .menu-openner{
              &:after{
                @include rotate(0deg);
              }
            }
            ul{
              display:block;
            }
          }
        }
      }
      .menu-openner{
        position: absolute;
        top:0;
        right:0;
        background: $main-color;
        color:#fff;
        width: 6rem;
        height: 6rem;
        @include media($menu-break){
          display: none;
        }
        &:after,
        &:before{
          content:"";
          width:70%;
          height:2px;
          background:#fff;
          position: absolute;
          left:15%;
          top:50%;
          margin-top:-1px;
        }
        &:after{
          @include rotate(90deg);
          @include transition;
        }
      }
    }
    &-hamburger{
      @include media($menu-break){
        display: none;
      }
      .open-menu{
        width:$hamburger-width;
        height:$hamburger-width;
        position: relative;
        display: block;
        span{
          width:$hamburger-width - 1rem;
          position: absolute;
          left:0.5rem;
          height:4px;
          background: $base-black;
          margin-top:-2px;
          top:50%;
          @include transition;
          @include border-radius(2px);
          &:before,
          &:after{
            content:"";
            //width:$hamburger-width - 1rem;
            right:0;
            height:4px;
            background: $base-black;
            position: absolute;
            @include transition;
            @include border-radius(2px);
          }
          &:before{
            top:-10px;
            width:$hamburger-width;
          }
          &:after{
            bottom:-10px;
            width:$hamburger-width - 2rem;
          }
        }
      }
    }
    &.open{
      .header-hamburger{
        .open-menu{
          span{
            background: transparent;
            &:before{
              top:0;
              width:$hamburger-width - 1rem;
              @include rotate(45deg);
            }
            &:after{
              top:0;
              width:$hamburger-width - 1rem;
              @include rotate(-45deg);
            }
          }
        }
      }
      .header-menu{
        //display: block;
        @include transform(translateX(0px));
      }
    }
  }
}

.EditMode,
.DesignMode {
  header {
    position: static;
  }
}

footer{
  padding: 40px 0;
  background: $base-black;
  color: $base-white;
  .footer{
    @include flex-block;
    @include align-items(center);
    @include justify-content(space-between);
    @media (max-width:767px){
      flex-direction: column;
      justify-content: center;
    }
    .footer-column{
      @media (max-width:767px){
        order: 1;
      }
    }
    .footer-column:first-child{
      @media (max-width:767px){
        order: 2;
        text-align: center;
        margin-top: 10rem;
      }
      .ref-row{
        padding-bottom: 6rem;
        ul{
          margin:0;
          padding:0;
          list-style: none;
          @include flex-block;
          @include align-items(center);
        }
        a{
          @media (max-width:767px){
            padding: 0 2rem;
          }
          padding-right: 6.5rem;
          color: $base-white;
          &:hover,
          &:focus{
            color: $main-color;
          }
        }
      }
    }
  }
}

.is-md{
    display:none;
    @include media(md){
        display:block;
    }
}

.is-lg{
    display:none;
    @include media(lg){
        display:block;
    }
}

.is-xl{
    display:none;
    @include media(xl){
        display:block;
    }
}

.is-xxl{
    display:none;
    @include media(xxl){
        display:block;
    }
}
